<template lang="pug">
.content
  .vertical-time-line
    .step
      .circle-holder
        .number 1
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.enterEmailAndPassword') }}
        .description
          .form-text {{ $t('integrationFlow.dotmailer.apiUser') }}
          .input-holder
            om-input#email.mb-auto.w-40.mr-3(
              v-model.trim="settings.username"
              type="text"
              :label="$t('integrationFlow.integrationModal.general.email')"
              :helpText="$t('integrationFlow.dotmailer.email')"
            )
              template(#error v-if="validations.username.$error && !validations.username.required")
                span {{ $t('requiredField') }}
          .input-holder
            om-input#password.mb-auto.w-40.mr-3(
              v-model.trim="settings.password"
              type="text"
              :label="$t('integrationFlow.integrationModal.general.password')"
              :helpText="$t('integrationFlow.dotmailer.password')"
            )
              template(#error v-if="validations.password.$error && !validations.password.required")
                span {{ $t('requiredField') }}
    .step
      .circle-holder
        .number 2
      .d-flex.container
        .title {{ $t('integrationFlow.integrationModal.general.addName') }}
        .description
          .input-holder
            om-input#name.mb-auto.w-100(
              v-model.trim="settings.name"
              type="text"
              :placeholder="$t('integrationFlow.integrationModal.general.namePlaceholder')"
              :helpText="$t('integrationFlow.integrationModal.general.inputHelp')"
            )
              template(#error v-if="validations.name.$error && !validations.name.required")
                span {{ $t('requiredField') }}
</template>
<script>
  export default {
    props: {
      settings: {
        type: Object,
        required: true,
      },
      validations: {
        type: Object,
        required: true,
      },
    },
  };
</script>
